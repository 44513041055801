import  axios  from "axios";
import { ref } from "vue";
const json = ref("/json")
const http = ref("https://cecwf.engops.work/api")
//const http = ref("http://localhost:5274/api")
export const getBanners = () =>{
    return axios.get(json.value+"/banner.json")
}
export const getBanners2 = () =>{
    return axios.get(http.value+"/Image/GetImages")
}

export const GetValidateCodeImages = (parms:{}) =>{
    return axios.get(http.value+"/User/GetValidateCodeImages?t="+parms)
}


export const getFlowers = (parms:{}) =>{
    return axios.post(http.value+"/Flower/GetFlowers",parms)
}

export const RegisterUsers = (parms:{}) =>{
    return axios.post(http.value+"/User/RegisterUsers",parms)
}

export const LoginUsers = (parms:{}) =>{
    return axios.post(http.value+"/User/LoginUsers",parms)
}

export const GetHeadCountList = (parms:{}) =>{
    console.log(parms)
    return axios.post(http.value+"/HeadCountList/GetHeadCountList?",parms)
}

export const HeadCountListUpdate = (parms:{}) =>{
    console.log(parms)
    return axios.post(http.value+"/HeadCountList/HeadCountListUpdate?",parms)
}

export const GetHiringStatusList = (parms:{}) =>{
    console.log(parms)
    return axios.post(http.value+"/HiringStatus/GetHiringStatusList?",parms)
}

export const HiringStatusListUpdate=(parms:{})=>{
    console.log(parms)
    return axios.post(http.value+"/HiringStatus/HiringStatusListUpdate?",parms);
}

